import { Flex, Text, Box, Divider, HStack } from "@chakra-ui/react";
import Image from "next/legacy/image";
import { LiaFileInvoiceSolid } from "react-icons/lia";
import { Item } from "@/components/Product";
import { ITEMS_MOCK } from "@/mock/bill";
import { YourShare } from "@/components/Share";
import { Tips } from "@/components/Tips";
import { Layout } from "@/components/Layout";
import { useContext, useEffect, useState } from "react";
import { GlobalContext, ItemType } from "./_app";
import { PaymentMethod } from "@/components/PaymentMethod";

const data = {
  id: "0c4429d7-1f80-4cfb-b98a-3b9191ac82f8",
  table_id: 611,
  restaurant: {
    name: "Trove",
    address_line_1: "6 Avenue Sainte Margueritte",
    country: "France",
    city: "Nice",
    logo: "https://cdn.qlub.io/temp/92023/lm8p6foyyh4oahlgzp_editedImage.png",
    background_image:
      "https://qlub-cloud.s3.ap-southeast-1.amazonaws.com/temp/92023/lm8p6m4tz8q2v3w27l_blob",
    service_charge_percentage: 0.02,
    currency: "EUR",
    tips_options: [0.1, 0.15, 0.2],
  },
  bill: {
    items: ITEMS_MOCK,
    remaining_items: ITEMS_MOCK,
    bill_is_paid: false,
    total_bill: 433951,
    remaining: 433951,
  },
};

export default function Home() {
  const [globalData, setGlobalData] = useContext(GlobalContext);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setGlobalData((prevGlobalData: any) => ({
      ...prevGlobalData,
      bill: {
        items: data.bill.items,
        remainingItems: data.bill.remaining_items,
        totalBill: data.bill.total_bill,
        remaining: data.bill.remaining,
        currency: data.restaurant.currency,
        tipsOptions: data.restaurant.tips_options,
      },
    }));
  }, []);

  useEffect(() => {
    if (globalData) {
      setLoading(false);
    }
  }, [globalData]);
  const consolidatedItems = globalData?.bill.items.reduce((result, item) => {
    const existingItem = result.find((i) => i.name === item.name);

    if (existingItem) {
      // If item with the same name exists, update quantity and total
      existingItem.qty += item.qty;
      if (existingItem.total !== undefined) {
        existingItem.total += item.qty * (item.price / item.qty); // Divide price by qty to get price per item
      }
    } else {
      // If item with the same name does not exist, add it to the result
      result.push({
        name: item.name,
        price: item.price / item.qty, // Divide price by qty to get price per item
        currency: item.currency,
        qty: item.qty,
        total: item.qty * (item.price / item.qty), // Divide price by qty to get price per item
      } as ItemType);
    }

    return result;
  }, [] as ItemType[]);

  // Calculate service charge
  let serviceCharge: number | undefined;

  if (
    globalData &&
    globalData.bill &&
    typeof globalData.bill.remaining === "number"
  ) {
    serviceCharge =
      globalData.bill.remaining -
      globalData.bill.remaining /
        (1 + data.restaurant.service_charge_percentage);
  } else {
    // Handle the case when globalData or bill.remaining is undefined
    serviceCharge = undefined; // or any default value you want
  }

  // Formated overall total amount
  const totalAmount = data.bill.remaining;

  if (loading) {
    return;
  }

  return (
    <Layout>
      <Box
        backgroundImage={data.restaurant.background_image}
        backgroundRepeat={"no-repeat"}
        backgroundSize={"cover"}
        backgroundAttachment={"fixed"}
        backgroundPosition={"center center"}
        m={0}
      >
        <Flex
          direction={"column"}
          pt={"120px"}
          w="100%"
          h="100%"
          backgroundColor={"blackAlpha.600"}
          justifyContent={"center"}
        >
          <Flex
            alignSelf={"center"}
            backgroundColor={"white"}
            minW={400}
            maxW={600}
            w="100%"
            py={20}
            px={5}
            borderTopRadius={10}
            direction={"column"}
          >
            {/* Header */}
            <Box
              alignSelf={"center"}
              mt={"-125px"}
              rounded={10}
              w={"80px"}
              h={"80px"}
              overflow={"hidden"}
              borderWidth={1}
            >
              <Image
                src={data.restaurant.logo}
                alt="background"
                width={80}
                height={80}
                objectFit="cover"
              />
            </Box>
            <Flex mt={8} w="100%" direction={"row"} justify={"space-between"}>
              <Text fontWeight={"semibold"}>Table: {data.table_id}</Text>
              <Box cursor={"pointer"} onClick={() => alert("View Menu")}>
                <Text color="black" fontWeight={"semibold"} cursor={"pointer"}>
                  View menu
                </Text>
              </Box>
            </Flex>
            {/* Divider */}
            <Divider mt={7} mb={5} borderColor={"gray.300"} />
            {/* Content */}
            {consolidatedItems &&
              consolidatedItems.map((item, index) => (
                <Item
                  key={index}
                  item={item}
                  currency={data.restaurant.currency}
                  onSelect={() => null}
                />
              ))}
            {/* Divider */}
            <Divider mt={5} borderColor={"gray.300"} />
            <Flex py={2} direction={"column"}>
              {!data.bill.bill_is_paid && (
                <Flex
                  w={"100%"}
                  justify={"space-between"}
                  fontWeight={"500"}
                  fontSize={"1rem"}
                  alignItems={"center"}
                  py={2}
                >
                  <HStack>
                    <Flex
                      alignItems={"center"}
                      justifyContent={"center"}
                      backgroundColor={"brand.500"}
                      w={6}
                      h={6}
                      rounded={50}
                    >
                      <LiaFileInvoiceSolid size={"12px"} />
                    </Flex>
                    <Box fontWeight={"semibold"}>
                      <Text>Service Charge</Text>
                    </Box>
                  </HStack>
                  <HStack
                    spacing={1}
                    fontWeight={"semibold"}
                    alignItems={"flex-end"}
                  >
                    <Text>{data.restaurant.currency}</Text>
                    <Text>
                      {serviceCharge !== undefined
                        ? (serviceCharge / 100).toFixed(2)
                        : "N/A"}
                    </Text>
                  </HStack>
                </Flex>
              )}
              <Flex
                w={"100%"}
                justify={"space-between"}
                fontWeight={"500"}
                fontSize={"1rem"}
                alignItems={"center"}
                py={2}
              >
                <HStack>
                  <Flex
                    alignItems={"center"}
                    justifyContent={"center"}
                    backgroundColor={"brand.500"}
                    w={6}
                    h={6}
                    rounded={50}
                  >
                    <LiaFileInvoiceSolid size={"12px"} />
                  </Flex>
                  <Box fontWeight={"semibold"}>
                    <Text>Total Bill</Text>
                  </Box>
                </HStack>
                <HStack>
                  <Text>{data.restaurant.currency}</Text>
                  <Text>{(totalAmount / 100).toFixed(2)}</Text>
                </HStack>
              </Flex>
            </Flex>
            {/* Divider */}
            <Divider borderColor={"gray.300"} />
            <YourShare />
            {/* Divider */}
            <Divider borderColor={"gray.300"} />
            <Tips isPaid={data.bill.bill_is_paid} />
            {/* Divider */}
            <Divider borderColor={"gray.300"} />
            <PaymentMethod />
          </Flex>
        </Flex>
      </Box>
    </Layout>
  );
}
